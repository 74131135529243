import MD5 from './md5'

/**
 * Extract data from the given Node with the following rules:
 *
 * - If target-field is 'productImage' or 'image',
 *      then extract the src attribute from the nearest IMG node (include child of the specified node)
 *      OR extract the background-image CSS attribute of specified node
 * - If a META element, then extract its 'content' attribute. If content attribute is not found, then fall back to 'value' attribute.
 * - If a non-META element, then return the trimmed 'innerText'.
 * - If a Text node, then return the trimmed 'textContent' (which can be null).
 * - Return null for all other cases.
 *
 * @param {Node} node the node to be examined
 * @param {String} targetField the field (i.e. productImage) in which the data is going to be stored. Optional.
 * @param {Document} theDocument the context document to be used to compute absolute URL of image
 */
function extractValue(node, targetField, theDocument) {
  if (!node) {
    return null
  }

  if (targetField === 'productImage' || targetField === 'image') {
    // Not an Element node
    if (node.nodeType !== 1) {
      return null
    }

    let capturedUrl = null

    if (/^meta$/i.test(node.tagName)) {
      // an OG meta element
      capturedUrl = _getContentOrValue(node)
    }

    if (!capturedUrl && /^img$/i.test(node.tagName)) {
      // an IMG element
      capturedUrl = node.src
    }

    if (!capturedUrl) {
      // find the first child/grandchild that is an IMG element
      let imgChildElement = node.querySelector('img')
      if (imgChildElement) {
        capturedUrl = imgChildElement.src
      } else {
        capturedUrl = _getComputedBackgroundImageURL(node)
      }
    }

    if (capturedUrl) {
      return _getAbsoluteUrl(capturedUrl, theDocument)
    }
  } else {
    let result = null

    // an Element node
    if (node.nodeType === 1) {
      // a META element
      if (/^meta$/i.test(node.tagName)) {
        result = _getContentOrValue(node)
      } else {
        result = node.innerText ? node.innerText.trim() : null
      }
    }

    // a Text node
    if (node.nodeType === 3) {
      let textContent = node.textContent
      result = textContent ? textContent.trim() : null
    }

    if (result && targetField === 'number') {
      const matches = result.replace(/[^0-9.]/g, '').match(/-?\d*\.?\d*/)

      if (matches && matches.length > 0) {
        if (/\./.test(matches[0])) {
          result = parseFloat(matches[0])
        } else {
          result = parseInt(matches[0])
        }
      } else {
        result = null
      }
    }

    return result
  }

  // for all other cases, return null
  return null
}

/**
 * Generate a hexidemical Id based on the provided URL.
 * Return null if the input is either NULL or empty String.
 *
 * @param {String} theURL the input URL
 */
function generateIdByURL(theURL) {
  if (!theURL || String(theURL).length === 0) {
    return null
  }
  return MD5.hexMD5(String(theURL))
}

/**
 * Copied from https://github.com/lodash/lodash/blob/master/attempt.js with slight modification to error handling.
 *
 * Attempts to invoke `func`, returning either the result or null when error is encountered.
 * Any additional arguments are provided to `func` when it's invoked.
 *
 */
function attempt(func, ...args) {
  try {
    return func(...args)
  } catch (e) {
    return null
  }
}

function newCacheBusterValue() {
  // round unix time to nearest hour
  return String(Math.floor(Date.now() / (1000 * 60 * 60)))
}

/**
 * Copied from https://github.com/github/fetch/issues/175#issuecomment-125779262
 *
 * @param {Number} ms time in millisecond to invoke reject method
 * @param {Promise} promise the promise to impose timeout constraint
 */
function timeout(ms, promise, process) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error(`response timeout after ${ms}ms on process ${process}`))
    }, ms, process)
    promise.then(resolve, reject)
  })
}

/**
 * Extends native fetch with timeout capability.
 * Timeout is hard-coded as 2 seconds.
 *
 */
function fetchWithTimeout() {
  return timeout(2000, fetch.apply(null, arguments),"fetchWithTimeout")
}

function _getContentOrValue(metaElement) {
  let result = null

  // open-graph and RDFa define that data to be found in content
  if (metaElement.hasAttribute('content')) {
    result = metaElement.getAttribute('content')
  }

  if (result && result.trim().length > 0) {
    return result
  }

  // fallback to 'value' if nothing is defined under 'content'
  if (metaElement.hasAttribute('value')) {
    result = metaElement.getAttribute('value')
  }

  if (result && result.trim().length > 0) {
    return result
  }

  return null
}

function _getComputedBackgroundImageURL(element) {
  if (!element) {
    return null
  }

  const computedStyles = getComputedStyle(element)

  if (!computedStyles) {
    return null
  }

  const backgroundImageStyle = computedStyles.getPropertyValue(
    'background-image'
  )

  if (!backgroundImageStyle) {
    return null
  }

  // look for pattern such as url("http://www.adacado.com/my-product")
  const matches = backgroundImageStyle.match(/^url\(['"]?([^'"]+)['"]?\)$/)

  if (!matches || matches.length < 2) {
    return null
  }

  return matches[1]
}

function _getAbsoluteUrl(input, theDocument) {
  if (!theDocument || !input || /^http/i.test(input)) {
    return input
  }
  try {
    // make use of anchor element to sanitize input
    let a = theDocument.createElement('a')
    a.href = input
    return a.href ? a.href : input
  } catch (err) {}
  return input
}

export default {
  attempt,
  extractValue,
  fetchWithTimeout,
  generateIdByURL,
  newCacheBusterValue
}
