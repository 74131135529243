/**
 * Project constants
 */
const ORG = 'productpixel'
const VERSION = 'v1'

/**
 * Environment constants
 */
const PRODUCTION = 'prod'
const STAGING = 'staging'
let ENVIRONMENT = PRODUCTION
// find the script element that loads this content by ID
const adacadoPixelScriptElement = document.getElementById('adavadael')
if (adacadoPixelScriptElement) {
  sourceURL = adacadoPixelScriptElement.getAttribute('src')

  // Determine the environment domain by looking at specific clues from referenced URL
  // change to either staging or local domain for the main library if necessary
  if (sourceURL && /staging|localhost|127.0.0.1/i.test(sourceURL)) {
    ENVIRONMENT = STAGING
  }
}

// This are all subdomains of adacado.com
const CONTENT_URL_SUBDOMAIN = 'content'
const DATA_TRACKING_URL_SUBDOMAIN = 'ad'
const INVENTORY_URL_SUBDOMAIN = 'wsa'
const REPORTING_URL_SUBDOMAIN = 'ad'

// Dynamically construct endpoints used in scraper-pixel
// We do this to obfuscate our urls so that external code
// scanners do not flag our code by the presence of blacklisted/suspicious-to-them
// urls in our code
function _constructDomain(subdomain) {
  // Begin with protocol
  let url = `https://`

  // Tack on sub-domain adjusted by environment
  switch (ENVIRONMENT) {
    case PRODUCTION:
      url += subdomain
      break
    default:
      // staging & local
      url += 'staging-' + subdomain
  }

  // Tack on domain broken up to hinder readability/beat code scanners
  url = `${url}.ada`
  return url + `cado.com`
}

/**
 * Files served by product-pixel SVC
 */

function LIB_URL() {
  return `${_constructDomain(CONTENT_URL_SUBDOMAIN)}/${ORG}/${VERSION}/prpx.umd.js`
}

function CONFIG_URL() {
  return `${_constructDomain(CONTENT_URL_SUBDOMAIN)}/${ORG}/${VERSION}/config/client.json`
}

/**
 * Resource URLs
 */

function DATA_TRACKING_PXL_URL() {
  return `${_constructDomain(DATA_TRACKING_URL_SUBDOMAIN)}/pixel/v1/`
}

function SCRAPER_CONFIG_URL() {
  return `${_constructDomain(CONTENT_URL_SUBDOMAIN)}/advertiser/v1/scraperconfig/`
}

function VERTICAL_FIELDS(vertical) {
  if (!vertical) return null
  return `${_constructDomain(CONTENT_URL_SUBDOMAIN)}/verticalfields/v1/${vertical}/getFields`
}

function INVENTORY_POST_URL(vertical) {
  if (!vertical) return null
  return `${_constructDomain(INVENTORY_URL_SUBDOMAIN)}/inventory-receiver/v1/${vertical}/`
}

function ACTIVITY_POST_URL() {
  return `${_constructDomain(REPORTING_URL_SUBDOMAIN)}/activity/scraperclient/`
}

function MEDIA_PIXEL_SCRIPT_CONTENT(advertiserid, mediaChannelType) {
  if (!advertiserid) return ''
  return `${_constructDomain(
    CONTENT_URL_SUBDOMAIN
  )}/advertiser/v1/pixel/${advertiserid}/${mediaChannelType}/default`
}

// eslint-disable-next-line no-undef
export default {
  PRODUCTION,
  STAGING,
  LIB_URL,
  CONFIG_URL,
  DATA_TRACKING_PXL_URL,
  SCRAPER_CONFIG_URL,
  VERTICAL_FIELDS,
  INVENTORY_POST_URL,
  ACTIVITY_POST_URL,
  MEDIA_PIXEL_SCRIPT_CONTENT,
}
