import Common from './common'
import Env from './env'

/**
 * Creates a script element and adds content to it by making a call to retrieve the script content
 * for the given media cannel type
 * @param {string} advertiserId
 * @param {string} mediaChannelType - dspcentro, facebook, etc
 */
function _getAndInsertPixelScript(advertiserId, mediaChannelType) {
  Common.fetchWithTimeout(Env.MEDIA_PIXEL_SCRIPT_CONTENT(advertiserId, mediaChannelType))
    .then(response => response.text())
    .then(response => {
      // if no response, then don't bother injecting - no pixel script
      if (response) {
        try {
          var newScriptElement = document.createElement('script')
          newScriptElement.text = response
          document.querySelector('body').appendChild(newScriptElement)
        } catch (error) {
          console.log(
            `Problem inserting ${mediaChannelType} pixel script. Will retry next time. Error: ${error}`
          )
        }
      }
    })
}

// FF and Chrome should take care of redundant execution of library
// BUT we should double check by using the following guard.
if (!window['AdavadAObject']) {
  var namespace = 'adavada'
  // important! element ID's are global variable, so we cannot re-use the same namespace
  var hintElementId = namespace + 'el'

  // TODO - sourceURL is being defined here and then assigned to later on in this file, but
  // it's not being used in this file. There is reference to a sourceURL variable in env.js, but it
  // is not clear if it is meant to be the sourceURL variable defined here, so, this really
  // needs to be verified and corrected
  var advertiserId, productId, sourceURL

  try {
    // leave a trail in the web-page indicating that at least this entry script has been run

    window['AdavadAObject'] = namespace

    // create a shell to capture commands before the main library is loaded and initialized
    window[namespace] =
      window[namespace] ||
      function() {
        ;(window[namespace].queue = window[namespace].queue || []).push(arguments)
        return
      }

    // find the script element that loads this content by ID
    var selfScriptElment = document.getElementById(hintElementId)
    if (selfScriptElment) {
      advertiserId = selfScriptElment.getAttribute('data-advertiserid')
      productId = selfScriptElment.getAttribute('data-productid')
      window.adacado = { productIdPassedIn: productId }
      sourceURL = selfScriptElment.getAttribute('src')
    }

    // Insertion of the prpx pixel script element
    var newScriptElement = document.createElement('script')
    newScriptElement.async = 1
    newScriptElement.src = Env.LIB_URL()
    var firstScriptElement = document.getElementsByTagName('script')[0]
    firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement)

    if (advertiserId > 0) {
      // insert media channel pixel script(s)
      _getAndInsertPixelScript(advertiserId, 'dspcentro')
      _getAndInsertPixelScript(advertiserId, 'facebook')

      // inject advertiser-id into the shell
      window[namespace]('set', 'config', 'advertiserId', advertiserId)
      window[namespace]('set', 'config', 'bootstrap', true)
    }
  } catch (err) {
    window[namespace].error = '[SHELL] ' + JSON.stringify(err ? err.message : '')
  }

  const noop = () => null
  // the "client" attribute of the main library allows synchronous execution of command
  // ignore all the calls made into the "client" before the main library is loaded
  window[namespace].client = noop
  // mock any static methods that are exported by the main library
  window[namespace].extractValue = noop
  window[namespace].generateIdByURL = noop

  window[namespace].initialized = false
}
